import Cookies from 'universal-cookie';
import { routesBackoffice } from '../app/routes';

export const cookieExists = () => {
  const cookies = new Cookies();
  let accessToken = cookies.get('access-token');

  return accessToken ? true : false;
};

export const getAuthCookie = () => {
  const cookies = new Cookies();
  return cookies.get('access-token');
};

export const removeAuthCookie = () => {
  const cookies = new Cookies();
  cookies.remove('access-token', {
    domain: window.location.hostname,
    path: '/',
    sameSite: 'Strict',
  });
};

export const logout = () => {
  removeAuthCookie();
  window.location.href = '/login';
};

export const redirectLogic = async (
  history,
  isAdmin,
  isOpQr,
  isMkQr,
  isOpRisk
) => {
  isAdmin
    ? history.push(routesBackoffice.dashboard)
    : isOpQr
    ? history.push(routesBackoffice.qrEstablecimientos)
    : isOpRisk
    ? history.push(routesBackoffice.riskChargebacks)
    : isMkQr
    ? history.push(routesBackoffice.qrImpresion)
    : history.push(routesBackoffice.usersPayway);
};
