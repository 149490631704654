import React from 'react';
import { P, Icon, Button } from '@phe/ui-components';
import { logout } from '../../../../utils/Utils';
import {
  USER_ROLE_ADMIN,
  USER_ROLE_OPERATOR,
  USER_ROLE_OPERATOR_QR,
  USER_ROLE_MARKETER_QR,
  USER_ROLE_OPERATOR_RISK,
} from '../../../../utils/RoleConstants';
import './Header.scss';

const Header = ({ user }) => {
  const userHasRoles = (user) =>
    user?.roles !== undefined && user?.roles.length !== 0;
  const hasRole = (user, rol) => user?.roles?.includes(rol);

  const mailFormat = (email) => {
    if (email) {
      const max = 15;
      const parenthesis = '(..)';
      const symbolIndex = email.indexOf('@');
      const username = email.slice(0, symbolIndex);
      let emailResult = '';
      if (username.length > max) {
        const domain = email.slice(symbolIndex, email.length);
        emailResult = username.slice(0, max - 1) + parenthesis + domain;
      } else {
        emailResult = email;
      }
      return emailResult;
    }
  };

  const renderTypeAccount = () => {
    const roleMapping = {
      [USER_ROLE_ADMIN]: 'ADMINISTRADOR',
      [USER_ROLE_OPERATOR]: 'OPERADOR',
      [USER_ROLE_OPERATOR_QR]: 'OPERADOR QR',
      [USER_ROLE_OPERATOR_RISK]: 'OPERADOR RIESGOS',
      [USER_ROLE_MARKETER_QR]: 'COMERCIAL QR',
    };

    const userRoles = userHasRoles(user) ? user.roles : [];

    const selectedRole = userRoles.find(
      (role) => roleMapping[role] !== undefined
    );

    return selectedRole ? roleMapping[selectedRole] : 'Sin roles asignados';
  };

  return (
    <div className='header'>
      <div className='header__perfil'>
        <div className='perfil__info'>
          <P
            type='2'
            variant='neutral'
            modifier='strong'
            weight='bold'
            align='right'
          >
            {mailFormat(user?.email)}
          </P>
          <P type='4' variant='neutral'>
            {renderTypeAccount()}
          </P>
        </div>
      </div>
      <div className='perfil__avatar'>
        <Icon
          name='user_circle_outline'
          height={28}
          width={28}
          variant='primary'
        />
      </div>
      <div className='header__logout'>
        <Button size='sm' variant='secondary' disabled={false} onClick={logout}>
          Cerrar sesión
        </Button>
      </div>
    </div>
  );
};

export default Header;
