import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from './Header/Header';
import './Layout.scss';
import useUser from '../../../hooks/useUser';
import { redirectLogic } from '../../../utils/Utils';
import SecurityRouter from './SecurityRouter/SecurityRouter';

const Layout = ({ left, right, isAdmin, isOpQr, isMkQr, isOpRisk }) => {
  const user = useUser();

  let history = useHistory();

  if (history.location.pathname === '/') {
    redirectLogic(history, isAdmin, isOpQr, isMkQr, isOpRisk);
  }

  return (
    <div className='layout' id='layout'>
      <Header user={user} />
      {left}
      <SecurityRouter>
        <main className='layout__content'>{right}</main>
      </SecurityRouter>
      <footer className='layout__footer' />
    </div>
  );
};

export default Layout;
