import React from 'react';
import './App.css';
import Layout from './app/components/Layout/Layout';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter } from 'react-router-dom';
import { getAuthCookie } from './utils/Utils';
import {
  USER_ROLE_ADMIN,
  USER_ROLE_MARKETER_QR,
  USER_ROLE_OPERATOR_QR,
  USER_ROLE_OPERATOR_RISK,
} from './utils/RoleConstants';
import { ThemeProvider, themeFree } from '@phe/ui-components';

function App(props) {
  const { roles } = props.user;

  let isAdmin = roles.includes(USER_ROLE_ADMIN);
  let isOpQr = roles.includes(USER_ROLE_OPERATOR_QR);
  let isOpRisk = roles.includes(USER_ROLE_OPERATOR_RISK);
  let isMkQr = roles.includes(USER_ROLE_MARKETER_QR);

  const renderContainer = (isAdmin, isOpQr, isMkQr) => {
    const cookie = getAuthCookie();
    if (!cookie) return <div id='single-spa-application:login' />;
    else
      return (
        <Layout
          isAdmin={isAdmin}
          isOpQr={isOpQr}
          isOpRisk={isOpRisk}
          isMkQr={isMkQr}
          left={<div id='single-spa-application:menu' />}
          right={
            <>
              <div id='single-spa-application:dashboard' />
              <div id='single-spa-application:feedback' />
              <div id='single-spa-application:automatic-debit' />
              <div id='single-spa-application:user' />
              <div id='single-spa-application:messages' />
              <div id='single-spa-application:qr' />
              <div id='single-spa-application:risk' />
              <div id='single-spa-application:online-sales' />
            </>
          }
        />
      );
  };

  return (
    <ThemeProvider theme={themeFree}>
      <BrowserRouter>
        {renderContainer(isAdmin, isOpQr, isMkQr, isOpRisk)}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
