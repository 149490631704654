import {
  USER_ROLE_MARKETER_QR,
  USER_ROLE_OPERATOR,
  USER_ROLE_OPERATOR_QR,
  USER_ROLE_OPERATOR_RISK,
} from '../utils/RoleConstants';

export const routesBackoffice = {
  start: '/',
  messages: '/mensajes',
  users: '/usuarios',
  usersPayway: '/usuarios/payway',
  dashboard: '/inicio',
  usersBackoffice: '/usuarios/backoffice',
  feedback: '/encuestas',
  qr: '/qr',
  qrEstablecimientos: '/qr/establecimientos',
  qrImpresion: '/qr/impresiones',
  risk: '/riesgos',
  riskChargebacks: '/riesgos/contracargos',
};

export const forbiddenRoutesPerRole = new Map([
  [
    USER_ROLE_OPERATOR,
    {
      messages: routesBackoffice.messages,
      dashboard: routesBackoffice.dashboard,
      usersBackoffice: routesBackoffice.usersBackoffice,
      feedback: routesBackoffice.feedback,
      qr: routesBackoffice.qr,
      qrEstablecimientos: routesBackoffice.qrEstablecimientos,
      risk: routesBackoffice.risk,
      riskChargebacks: routesBackoffice.riskChargebacks,
    },
    USER_ROLE_OPERATOR_QR,
    {
      messages: routesBackoffice.messages,
      usersPayway: routesBackoffice.usersPayway,
      usersBackoffice: routesBackoffice.usersBackoffice,
      dashboard: routesBackoffice.dashboard,
      feedback: routesBackoffice.feedback,
      risk: routesBackoffice.risk,
      riskChargebacks: routesBackoffice.riskChargebacks,
    },
    USER_ROLE_MARKETER_QR,
    {
      qrImpresion: routesBackoffice.qrImpresion,
    },
  ],
]);
