import { secureGet } from '../../utils/HttpUtils';

let userData = {};

export const getUserService = () => {
  if (Object.keys(userData).length > 0) return Promise.resolve(userData);

  return secureGet(`/api/bff-user-backoffice/private/users/validates`, {}).then(
    (data) => {
      userData = data;
      return userData;
    }
  );
};
