import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { registerApplication, start } from 'single-spa';
import { phePortalMap } from './utils/Constants';
import { getUserService } from './app/services/UserService';
import { removeAuthCookie } from './utils/Utils';

const secureMicrofronts = [
  {
    name: 'menu',
    app: '@prisma/menu-backoffice',
    activeWhen: '/',
  },
  {
    name: 'automatic-debit',
    app: '@prisma/automatic-debit-backoffice',
    activeWhen: '/debito-automatico',
  },
  {
    name: 'user',
    app: '@prisma/user-backoffice',
    activeWhen: '/usuarios',
  },
  {
    name: 'messages',
    app: '@prisma/messages-backoffice',
    activeWhen: '/mensajes',
  },
  {
    name: 'dashboard',
    app: '@prisma/dashboard-backoffice',
    activeWhen: '/inicio',
  },
  {
    name: 'feedback',
    app: '@prisma/feedback-backoffice',
    activeWhen: '/encuestas',
  },
  {
    name: 'qr',
    app: '@prisma/qr-backoffice',
    activeWhen: '/qr',
  },
  {
    name: 'risk',
    app: '@prisma/risk-backoffice',
    activeWhen: '/riesgos',
  },
  {
    name: 'online-sales',
    app: '@prisma/online-sales-backoffice',
    activeWhen: '/ventas-online',
  },
];

const microfronts = [
  {
    name: 'login',
    app: '@prisma/login-backoffice',
    activeWhen: '/login',
  },
];

const urlExcludes = [
  '/login/crear-usuario/',
  '/login/asociar-usuario/',
  '/login/recuperar',
  '/login/cambiar-clave/',
  '/login/not-found',
];

const configureMicrofronts = (
  microfronts,
  userRoles = null,
  permissions = null
) => {
  microfronts.map((config) =>
    registerApplication({
      ...config,
      app: () => window.System.import(config.app),
      customProps: () => {
        if (userRoles || permissions) {
          return {
            PHE_PORTAL_URL: phePortalMap[window.location.hostname],
            userRoles: userRoles,
            permissions: permissions,
          };
        }
      },
    })
  );
};

const securedUrl = urlExcludes.some((url) =>
  window.location.pathname.includes(url)
);

if (!securedUrl) {
  getUserService()
    .then(({ data: user }) => {
      configureMicrofronts(secureMicrofronts, user.roles, user.permissions);

      ReactDOM.render(
        <React.StrictMode>
          <App user={user} />
        </React.StrictMode>,
        document.getElementById('root')
      );
    })
    .catch((error) => {
      const loginUrl = '/login';
      if (window.location.pathname !== loginUrl) {
        removeAuthCookie();
        window.location = loginUrl;
      }
      configureMicrofronts(microfronts);
    })
    .finally(() => {
      start();
    });
} else {
  configureMicrofronts(microfronts);
  start();
}
